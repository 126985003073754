<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <strong class="bold">{{currentUserBind.account}}</strong>
      <img
        :src="currentUserBind.infoAccount.anh_dai_dien ? currentUserBind.infoAccount.anh_dai_dien : 'img/user.png'"
        class="img-avatar"
      />
    </template>
    <template slot="dropdown">
      <b-card :no-body="true" footer-class="px-3 py-2" class="mb-0 header-card-account">
        <b-card-body class="p-3 clearfix">
          <!-- <i class="icon-user bg-primary p-3 font-2xl mr-3 float-left"></i> -->
          <div class="mr-3 float-left">
            <img
              :src="currentUserBind.infoAccount.anh_dai_dien ? currentUserBind.infoAccount.anh_dai_dien : 'img/user.png'"
              class="img-avatar"
              width="50"
            />
          </div>
          <div></div>
          <div class="h6 text-info mb-0 mt-2 mb-1 uppercase">{{currentUserBind.infoAccount.name}}</div>
          <div
            class="font-xs text-muted text-uppercase bold-500"
          >{{currentUserBind.infoAccount.position}}
          </div>
        </b-card-body>
        <!-- <div slot="footer">
          <b-link
            class="font-weight-bold font-xs btn-block text-muted"
            @click="fn_gotoInFoAccount()"
          >
            Chi tiết thông tin cá nhân
            <i class="fa fa-angle-right float-right font-lg"></i>
          </b-link>
        </div>-->
      </b-card>

      <!-- <b-dropdown-header tag="div" class="text-center">
        <strong>{{currentUserBind.infoAccount.name}} - {{currentUserBind.infoAccount.position}}</strong>
      </b-dropdown-header>-->

      <!-- Hiển thị thông báo -->
      <!-- <b-dropdown-item>
        <i class="fa fa-bell-o" /> Thông báo
        <b-badge variant="info">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item>
        <i class="fa fa-envelope-o" /> Tin nhắn
        <b-badge variant="success">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item>
        <i class="fa fa-tasks" /> Công việc
        <b-badge variant="danger">{{ itemsCount }}</b-badge>
      </b-dropdown-item>-->

      <!-- <b-dropdown-item><i class="fa fa-comments" /> Comments
        <b-badge variant="warning">{{ itemsCount }}</b-badge>
      </b-dropdown-item>-->
      <!-- <b-dropdown-header
        tag="div"
        class="text-center">
        <strong>Settings</strong>
      </b-dropdown-header>
      <b-dropdown-item><i class="fa fa-user" /> Profile</b-dropdown-item>
      <b-dropdown-item><i class="fa fa-wrench" /> Settings</b-dropdown-item>
      <b-dropdown-item><i class="fa fa-usd" /> Payments
        <b-badge variant="secondary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item><i class="fa fa-file" /> Projects
        <b-badge variant="primary">{{ itemsCount }}</b-badge>
      </b-dropdown-item>-->
      <!-- <b-dropdown-divider /> -->
      <!-- <b-dropdown-item>
        <i class="fa fa-shield" /> Lock Survey
      </b-dropdown-item>-->
      <b-dropdown-item router-link to="/auth/changepassword">
        <i class="icon-key"/>
        {{aLang.header_goChangePassword}}
      </b-dropdown-item>
      <b-dropdown-item @click="fn_logout()">
        <i class="icon-logout"/>
        {{aLang.logout}}
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
  import {HeaderDropdown as AppHeaderDropdown} from "@coreui/vue";

  import AuthService from "../service/AuthService";
  import LanguageService from "../service/LanguageService";
  // import ProfileApi from "../moduleApi/ProfileApi";
  import MethodService from "../service/MethodService";
  import ExtensionService from "../service/ExtensionService";

  export default {
    name: "DefaultHeaderDropdownAccnt",
    components: {
      AppHeaderDropdown
    },
    data: () => {
      return {
        aLang: LanguageService.lang,
        itemsCount: 0,
        currentUser: {},
        currentUserBind: {
          account: "",
          // roles: ["Admin"],
          infoAccount: {}
        }
      };
    },
    methods: {
      fn_logout() {
        this.$confirm(LanguageService.lang.logout, {
          confirmButtonText: LanguageService.lang.c_yes,
          cancelButtonText: LanguageService.lang.c_no,
          type: "info"
        })
          .then(() => {
            AuthService.logout(false)
              .then(res => {
                this.$message({
                  showClose: true,
                  message: this.aLang.logout_success,
                  type: "success"
                });
                this.$router.push({ name: "Auth-Login" });
              })
              .catch(error => {
                this.$message({
                  showClose: true,
                  message: this.aLang.logout_error,
                  type: "error"
                });
              });
          })
          .catch();
        // this.$message({
        //   showClose: true,
        //   message: this.aLang.logout_success,
        //   type: "success"
        // });
        // this.$router.push({name: "Auth-Login"});
      },
      fn_setRole() {
        if (this.currentUser.roles.indexOf("AdminSystem") != -1) {
          this.currentUser.infoAccount.position =
            "Quản trị hệ thống (AdminSystem)";
          this.currentUser.infoAccount.name = this.currentUser.infoAccount.name
            ? this.currentUser.infoAccount.name : "Super admin";
        }
        if (this.currentUser.roles.indexOf("Admin") != -1) {
          this.currentUser.infoAccount.position = "Quản trị hệ thống (Admin)";
          this.currentUser.infoAccount.name = this.currentUser.infoAccount.name
            ? this.currentUser.infoAccount.name : "Admin";
        }
        this.currentUserBind = this.currentUser;
      },
      fn_getAccount() {
        this.currentUser = MethodService.getCurrentUser();
        console.log(this.currentUser);
        // this.currentUser.roles = ["Admin"];
        this.currentUser.infoAccount = {
          email: "",
          name: this.currentUser.account,
          phoneNumber: "",
          position: ""
        };
        console.log("Thông tin người dùng");
        console.log(this.currentUser);

            // ProfileApi.findByIdRootScope(this.currentUser.id)
            //   .then(res => {
            //     if (res.result) {
            //       this.currentUser.infoAccount = res.result;
            //       this.currentUser.infoAccount.name = res.result.ho_ten.ho_ten;
            //       if (res.result.avatar_id) {
            //         this.currentUser.infoAccount.anh_dai_dien =
            //           ExtensionService.urlImg + res.result.avatar_id;
            //       }
            //     }
            //     this.fn_setRole();
            //   })
            //   .catch(error => {
            //     this.fn_setRole();
            //   });
            this.fn_setRole();
        },
        fn_gottoInFoAccount() {
            this.$router.push({
                name: "Admin-NguoiDung-update",
                params: { id: this.currentUserBind.id }
            });
        }
    },
    created() {
      this.fn_getAccount();
    }
  };
</script>
