<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="/admin/home">
        <img class="navbar-brand-full" src="img/logo.png" width="auto" height="80%" alt="Logo" />
        <img class="navbar-brand-minimized" src="img/logo.png" width="auto" height="80%" alt="Logo" />
      </b-link>
      <SidebarToggler class="d-md-down-none hidden" display="lg" />
      <!-- <SidebarMinimizer class="d-md-down-none" display="lg" /> -->
      <!-- <b-navbar-nav class="d-md-down-none">
        <b-nav-item class="px-3" to="/dashboard">Dashboard</b-nav-item>
        <b-nav-item class="px-3" to="/users" exact>Users</b-nav-item>
        <b-nav-item class="px-3">Settings</b-nav-item>
      </b-navbar-nav>-->
      <b-navbar-nav class="ml-auto">
        <!-- list icon -->
        <!-- <b-nav-item class="d-md-down-none">
          <i class="icon-bell icons"></i>
          <b-badge pill variant="danger">5</b-badge>
        </b-nav-item>-->
        <!-- <b-nav-item class="d-md-down-none">
          <i class="icon-list"></i>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i class="icon-location-pin"></i>
        </b-nav-item>-->

        <!-- account -->
        <DefaultHeaderDropdownAccnt />
      </b-navbar-nav>

      <!-- menu right -->
      <!-- <AsideToggler class="d-none d-lg-block" /> -->
      <!-- <AsideToggler class="d-lg-none" mobile /> -->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <!-- <Breadcrumb :list="list"/> -->
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <!-- <DefaultAside /> -->
      </AppAside>
    </div>

    <!--footer-->
    <!-- <TheFooter>      
      <div>
        <a href="https://coreui.io">CoreUI</a>
        <span class="ml-1">&copy; 2018 creativeLabs.</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://coreui.io">CoreUI for Vue</a>
      </div>
    </TheFooter>-->
  </div>
</template>

<script>
import MethodService from "../service/MethodService";
import nav from "@/_nav";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb
} from "@coreui/vue";
import DefaultAside from "./DefaultAside";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";

export default {
  name: "DefaultContainer",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data() {
    return {
      nav: MethodService.copyObject(nav.items)
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    }
  },
  methods: {
    fn_getNav() {
      let currentUser = MethodService.getCurrentUser();
      let arrNav = this.nav;

      $.each(arrNav, (indexNav1, valueNavLever1) => {
        if (
          valueNavLever1.meta.allowAccess &&
          valueNavLever1.meta.allowAccess.length > 0
        ) {
          //check quyền truy cập Nav1
          $.each(
            valueNavLever1.meta.allowAccess,
            (indexMeta1, valueMetaLever1) => {
              if (
                currentUser.roles.indexOf(valueMetaLever1) != -1 ||
                valueMetaLever1 == "*"
              ) {
                valueNavLever1.meta.allow = true;
              }
            }
          );
          //check xem tồn tại Nav2
          if (valueNavLever1.children && valueNavLever1.children.length > 0) {
            $.each(valueNavLever1.children, (indexNav2, valueNavLever2) => {
              if (
                valueNavLever2.meta.allowAccess &&
                valueNavLever2.meta.allowAccess.length > 0
              ) {
                //check quyền truy cập Nav2
                $.each(
                  valueNavLever2.meta.allowAccess,
                  (indexMeta2, valueMetaLever2) => {
                    if (
                      currentUser.roles.indexOf(valueMetaLever2) != -1 ||
                      valueMetaLever2 == "*"
                    ) {
                      valueNavLever2.meta.allow = true;
                    }
                  }
                );
                //check xem tồn tại Nav3
                if (
                  valueNavLever2.children &&
                  valueNavLever2.children.length > 0
                ) {
                  $.each(
                    valueNavLever2.children,
                    (indexNav3, valueNavLever3) => {
                      if (
                        valueNavLever3.meta.allowAccess &&
                        valueNavLever3.meta.allowAccess.length > 0
                      ) {
                        //check quyền truy cập Nav3
                        $.each(
                          valueNavLever3.meta.allowAccess,
                          (indexMeta3, valueMetaLever3) => {
                            if (
                              currentUser.roles.indexOf(valueMetaLever3) !=
                                -1 ||
                              valueMetaLever3 == "*"
                            ) {
                              valueNavLever3.meta.allow = true;
                            }
                          }
                        );
                        //Mở rộng thêm ở đây
                      }
                    }
                  );
                }
              }
            });
          }
        }
      });
      //Check Lọc quyền Nav1 và trả về mảng đã lọc arrNavFilter
      let arrNavFilter = arrNav.filter(function(value, index, arr) {
        return value.meta.allow == true;
      });
      $.each(arrNavFilter, function(indexNavF1, valueNavF1) {
        //check tồn tại Nav2
        if (valueNavF1.children && valueNavF1.children.length > 0) {
          // Lọc quyền Nav2 và trả về mảng đã lọc arrNavFilter2
          let arrNavFilter2 = valueNavF1.children.filter(function(
            value2,
            index2,
            arr2
          ) {
            return value2.meta.allow == true;
          });
          //gán mảng Nav2 đã lọc vào mảng mẹ
          valueNavF1.children = arrNavFilter2;
          $.each(arrNavFilter2.children, function(indexNavF12, valueNavF2) {
            //check tồn tại Nav3
            if (valueNavF2.children && valueNavF2.children.length > 0) {
              // Lọc quyền Nav3 và trả về mảng đã lọc arrNavFilter3
              var arrNavFilter3 = valueNavF2.children.filter(function(
                value3,
                index3,
                arr3
              ) {
                return value3.meta.allow == true;
              });
              //gán mảng Nav3 đã lọc vào mảng mẹ
              valueNavF2.children = arrNavFilter3;
              // Mở rộng tiếp theo ở đây
            }
          });
        }
      });

      // Gán mảng mẹ vào Nav Main
      this.nav = arrNavFilter;
    }
  },
  created() {
    this.fn_getNav();
  }
};
</script>
